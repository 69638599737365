import React, { useState } from 'react';
import './App.css';
import logo from './logo.png';
import backgroundImage from './background.jpg';
import { FaRegLightbulb, FaMapMarkerAlt, FaCloudSun, FaCameraRetro } from 'react-icons/fa';
import { db } from './firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

function App() {
  // State for email input and feedback message
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add email to Firestore 'newsletter' collection
      await addDoc(collection(db, 'newsletter'), {
        email: email,
        subscribedAt: serverTimestamp(),
      });
      setMessage('Thank you for subscribing!');
      setEmail(''); // Clear input field
    } catch (error) {
      console.error('Error adding document: ', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      {/* Hero Section with Background */}
      <header className="Hero-background" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="Hero-content">
          <img src={logo} className="App-logo" alt="App Logo" />
          {/* <h1 className="App-title">Destinator</h1> */}
          <h2 className="App-tagline">Your Personalized Travel Planner</h2>
          <p className="App-description">
            Welcome to <strong>Destinator</strong>, the AI-powered travel planning app that helps you discover personalized travel destinations based on your unique preferences.
          </p>
        </div>
      </header>

      {/* Features Section */}
      <section className="Features">
        <h3>Key Features:</h3>
        <ul className="Feature-list">
          <li>
            <FaMapMarkerAlt className="Feature-icon" />
            <strong>Personalized Recommendations:</strong> Get suggestions tailored to your preferences like climate, budget, and activities.
          </li>
          <li>
            <FaRegLightbulb className="Feature-icon" />
            <strong>AI-Powered Insights:</strong> Leverage artificial intelligence to find hidden gems and unique experiences.
          </li>
          <li>
            <FaCloudSun className="Feature-icon" />
            <strong>Real-Time Data:</strong> Access up-to-date info on weather, events, and local activities.
          </li>
          <li>
            <FaCameraRetro className="Feature-icon" />
            <strong>Visual Inspiration:</strong> Enjoy engaging visuals for each destination.
          </li>
        </ul>
      </section>

      {/* Launch Announcement */}
      <section className="Launch-info">
        <h3>Coming Soon!</h3>
        <p>
          We are excited to announce that <strong>Destinator</strong> will be launching in <strong>Q2 2025</strong>. Stay tuned for updates!
        </p>
      </section>

      {/* Newsletter Signup */}
      <section className="Contact">
        <h3>Stay Informed</h3>
        <p>Subscribe to our newsletter to receive the latest updates:</p>
        <form className="Contact-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            required
            className="Email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="Subscribe-button">Subscribe</button>
        </form>
        {message && <p className="Subscription-message">{message}</p>}
      </section>

      {/* Contact Information */}
      <section className="Contact-info">
        <h3>Contact Us</h3>
        <p>
          For any questions or support, feel free to reach us at: <a href="mailto:info@destinator.ai">info@destinator.ai</a>
        </p>
      </section>

      {/* Footer */}
      <footer className="App-footer">
        <p>&copy; 2024 Destinator. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
